import React, { useState, useEffect } from 'react';
import { NotificationHandler } from './NotificationHandler';
import SmartInstallPrompt from './components/SmartInstallPrompt';
import WalletPassButton from './components/WalletPassButton';

const App = () => {
  const [status, setStatus] = useState('unchecked');
  const [error, setError] = useState(null);
  const [notificationHandler] = useState(() => new NotificationHandler());

  useEffect(() => {
    checkNotificationSupport();
  }, []);

  const checkNotificationSupport = async () => {
    try {
      const isSubscribed = await notificationHandler.initialize();
      setStatus(isSubscribed ? 'subscribed' : 'initialized');
    } catch (error) {
      setStatus('error');
      setError(error.message);
    }
  };

  const handleEnableNotifications = async () => {
    try {
      // Clear any previous errors
      setError(null);
      
      // Request permission first
      await notificationHandler.requestPermission();
      
      // Then subscribe to push
      await notificationHandler.subscribeToPush();
      setStatus('subscribed');
    } catch (error) {
      setStatus('error');
      setError(error.message);
    }
  };

  const handleDisableNotifications = async () => {
    try {
      setError(null);
      await notificationHandler.unsubscribeFromPush();
      setStatus('initialized');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTestNotification = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: 'Test Notification',
          body: 'This is a test notification from our PWA!'
        }),
      });
      
      const result = await response.json();
      console.log('Notification sent:', result);
    } catch (error) {
      setError('Failed to send test notification: ' + error.message);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">PWA Notification Test</h1>
      <div className="mb-4">
        <p>Status: {status}</p>
        {error && (
          <p className="text-red-500">Error: {error}</p>
        )}
      </div>
      <div className="space-x-4">
        {status !== 'subscribed' && (
          <button
            onClick={handleEnableNotifications}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={status !== 'initialized'}
          >
            Enable Notifications
          </button>
        )}
        
        {status === 'subscribed' && (
          <>
            <button
              onClick={handleTestNotification}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Send Test Notification
            </button>
            <button
              onClick={handleDisableNotifications}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Disable Notifications
            </button>
          </>
        )}
      </div>
      <SmartInstallPrompt />
      <WalletPassButton />
    </div>
  );
};

export default App;