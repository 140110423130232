export class NotificationHandler {
  constructor() {
    this.swRegistration = null;
    this.publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
  }

  async initialize() {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      throw new Error('Push notifications not supported');
    }

    try {
      this.swRegistration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('Service Worker registered');

      // Check existing subscription
      const subscription = await this.swRegistration.pushManager.getSubscription();
      return subscription !== null;
    } catch (error) {
      console.error('Initialization error:', error);
      throw new Error('Failed to initialize push notifications');
    }
  }

  async requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Notification permission not granted');
    }
    return permission;
  }

  async unsubscribeFromPush() {
    try {
      const subscription = await this.swRegistration.pushManager.getSubscription();
      if (subscription) {
        // Unsubscribe from push
        await subscription.unsubscribe();
        // Notify backend about unsubscription
        await fetch(`${process.env.REACT_APP_API_URL}/unsubscribe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscription })
        });
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      throw new Error('Failed to unsubscribe from push notifications');
    }
  }

  async subscribeToPush() {
    try {
      // First, unsubscribe from any existing subscription
      await this.unsubscribeFromPush();

      // Then create new subscription
      const subscription = await this.swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey)
      });

      // Send subscription to server
      await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscription)
      });

      return subscription;
    } catch (error) {
      console.error('Subscription error:', error);
      throw new Error('Failed to subscribe to push notifications');
    }
  }

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}