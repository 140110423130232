import React, { useState } from 'react';

const WalletPassButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddToWallet = async () => {
    try {
      setLoading(true);
      setError(null);

      // Request the pass file
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallet-pass`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to generate pass');
      }

      // Get the pass file as blob
      const passBlob = await response.blob();

      // Create a download link
      const url = window.URL.createObjectURL(passBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `pass-${'a61a6d77-f3e1-47bc-88c7-8a6541eb020a'}.pkpass`;

      // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Detect iOS device
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // if (!isIOS) {
  //   return (
  //     <div className="text-yellow-600 p-4 rounded bg-yellow-50">
  //       Apple Wallet is only available on iOS devices.
  //     </div>
  //   );
  // }

  return (
    <div className="space-y-4">
      <button
        onClick={handleAddToWallet}
        disabled={loading}
        className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
          loading ? 'bg-gray-300' : 'bg-black'
        } text-white`}
      >
        {loading ? (
          <span>Generating Pass...</span>
        ) : (
          <>
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
            </svg>
            <span>Add to Apple Wallet</span>
          </>
        )}
      </button>
      
      {error && (
        <div className="text-red-600 p-4 rounded bg-red-50">
          Error: {error}
        </div>
      )}
    </div>
  );
};

export default WalletPassButton;