import React, { useEffect, useState } from 'react';
import { useInstallPrompt } from '../hooks/useInstallPrompt';

const SmartInstallPrompt = () => {
  const { isInstallable, isInstalled, handleInstall } = useInstallPrompt();
  const [deviceType, setDeviceType] = useState('unknown');
  const [browser, setBrowser] = useState('unknown');

  useEffect(() => {
    // Detect device type
    const ua = navigator.userAgent;
    if (/iPhone|iPad|iPod/.test(ua)) {
      setDeviceType('ios');
    } else if (/Android/.test(ua)) {
      setDeviceType('android');
    } else {
      setDeviceType('desktop');
    }

    // Detect browser
    if (/CriOS/.test(ua)) {
      setBrowser('chrome-ios');
    } else if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
      setBrowser('safari');
    } else if (/Chrome/.test(ua)) {
      setBrowser('chrome');
    }
  }, []);

  if (isInstalled || (!isInstallable && deviceType !== 'ios')) {
    return null;
  }

  const getInstallInstructions = () => {
    if (deviceType === 'ios' && browser === 'safari') {
      return (
        <div className="space-y-2">
          <p>To install this app on your iPhone:</p>
          <ol className="list-decimal list-inside space-y-1">
            <li>Tap the share button <span className="inline-block w-6 h-6">⎋</span></li>
            <li>Scroll down and tap "Add to Home Screen"</li>
            <li>Tap "Add" to confirm</li>
          </ol>
        </div>
      );
    }

    if (deviceType === 'ios') {
      return (
        <p>Please open this website in Safari to install the app on your iPhone</p>
      );
    }

    return (
      <div className="space-y-2">
        <p>Install our app for the best experience</p>
        <button
          onClick={handleInstall}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Install App
        </button>
      </div>
    );
  };

  return (
    <div className="fixed bottom-4 left-4 right-4 bg-white shadow-lg rounded-lg p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <img 
            src="/hub.png" 
            alt="App Icon" 
            className="w-12 h-12 rounded-xl"
          />
          <div className="flex-1">
            {getInstallInstructions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartInstallPrompt;